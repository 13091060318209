<template>
  <div id="signup-view">
    <b-overlay>
      <section class="bg-light ftco-section">
        <div class="d-flex justify-content-center">
          <img
            src="@/assets/site/images/loader.gif"
            v-if="loader === true"
            class="img-fluid loader-width"
            alt
          />
        </div>
        <div class="container" v-if="!loader">
          <div class="row flex-lg-row flex-md-row">
            <div class="col-md-5">
              <div style="margin-top: 2rem">
                <div class="card-body bg-dark-gray">
                  <h2 class="text-black mb-4">Sign up</h2>
                  <form>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="username"
                        placeholder="User Name*"
                      />
                      <span class="text-danger" v-if="errors.username">{{
                        this.errors.username[0]
                      }}</span>
                    </div>
                    <div class="form-group">
                      <input
                        type="text"
                        class="form-control"
                        v-model="name"
                        placeholder="Name*"
                      />
                      <span class="text-danger" v-if="errors.name">{{
                        this.errors.name[0]
                      }}</span>
                    </div>

                    <div class="form-group">
                      <input
                        type="email"
                        class="form-control"
                        v-model="email"
                        placeholder="Email Address*"
                      />
                      <span class="text-danger" v-if="errors.email">{{
                        this.errors.email[0]
                      }}</span>
                    </div>
                    <div class="form-group">
                      <div class="row">
                        <div class="col-md-2 pr-0">
                          <vue-country-code
                            @onSelect="onSelect"
                            :defaultCountry="country"
                            disabledFetchingCountry
                            enabledCountryCode
                            enabledFlags
                          >
                          </vue-country-code>
                        </div>
                        <div class="col-md-10 pl-0">
                          <input
                            type="text"
                            class="form-control"
                            v-model="mobile"
                            placeholder="Mobile Number"
                          />
                        </div>
                      </div>
                      <span class="text-danger" v-if="errors.mobile">{{
                        this.errors.mobile[0]
                      }}</span>
                    </div>
                    <div class="form-group">
                      <b-form-group class="font-weight-600">
                        <label class="font-weight-600 mb-3">Gender</label>
                        <div class="row pl-3">
                          <div class="col-md-3">
                            <b-form-radio
                              size="lg"
                              v-model="gender"
                              name="male"
                              value="Male"
                              class="radio-control"
                              >Male</b-form-radio
                            >
                          </div>
                          <div class="col-md-3">
                            <b-form-radio
                              size="lg"
                              v-model="gender"
                              name="female"
                              value="Female"
                              class="radio-control"
                              >Female</b-form-radio
                            >
                          </div>
                          <span class="text-danger" v-if="errors.gender">{{
                            this.errors.gender[0]
                          }}</span>
                        </div>
                      </b-form-group>
                    </div>
                    <div class="form-group mt-3">
                      <input
                        type="password"
                        class="form-control"
                        v-model="password"
                        placeholder="Password*"
                      />
                      <span class="text-danger" v-if="errors.password">{{
                        this.errors.password[0]
                      }}</span>
                    </div>
                    <div class="form-group">
                      <input
                        type="password"
                        class="form-control"
                        v-model="confirm_password"
                        placeholder="Confirm Password*"
                      />
                      <span
                        class="text-danger"
                        v-if="errors.confirm_password"
                        >{{ this.errors.confirm_password[0] }}</span
                      >
                    </div>
                    <div class="row ml-2">
                      <vue-recaptcha
                        sitekey="6LdOEbUcAAAAAJD8wvT14kaBQ-RSrgPZsmMXcbMr"
                        :loadRecaptchaScript="true"
                        @verify="onVerify"
                        @error="errorMethod"
                      >
                      </vue-recaptcha>
                    </div>
                    <div class="row ml-2">
                      <span class="text-danger" v-if="captchaError"
                        ><b>Please check the recaptcha box </b></span
                      >
                    </div>
                    <span class="text-danger" v-if="errors.gRecaptcha">{{
                      this.errors.gRecaptcha[0]
                    }}</span>
                    <div class="row mb-1">
                      <!-- /.col -->
                      <div class="mt-4 mb-2 ml-3">
                        <button
                          @click="sendOtp"
                          type="button"
                          class="btn btn-primary btn-block px-4"
                        >
                          Create Account
                        </button>
                      </div>
                    </div>
                    <p style="font-size: 14px">
                      Already have an account?
                      <router-link to="login" exact>Login</router-link>
                    </p>
                    <!-- /.col -->
                  </form>
                </div>
                <!-- /.login-card-body -->
              </div>
            </div>
            <b-modal id="sms-modal" centered title="" size="mb">
              <section class="mx-3" style="max-height: 280px;">
                <p>
                  Enter OTP, It has been sent to your registered mobile number
                  and email address.
                </p>
                <p />
                <input
                  type="text"
                  class="form-control"
                  v-model="otp"
                  placeholder="Enter OTP"
                />
              </section>
              <template #modal-footer="{ ok }">
                <button
                  class="btn btn-primary my-4 col-md-12"
                  @click="checkOtp(otp)"
                >
                  ok
                </button>
              </template>
            </b-modal>
            <div class="col-md-7">
              <img
                src="@/assets/site/images/login-bg.jpg"
                alt=""
                class="img-fluid"
              />
            </div>
          </div>
        </div>
      </section>
    </b-overlay>
  </div>
</template>

<script>
import Vue from "vue";
import VueCountryCode from "vue-country-code";
import VueRecaptcha from "vue-recaptcha";

Vue.use(VueCountryCode);
export default {
  name: "SignupView",
  components: { VueRecaptcha },
  data() {
    return {
      name: null,
      gender: "",
      username: null,
      email: null,
      password: null,
      mobile: null,
      otp: null,
      login_mode: "Manual",
      unique_id: null,
      confirm_password: null,
      errors: [],
      options: [],
      loader: false,
      enabledCountryCode: true,
      enabledFlags: false,
      countrycode: "",
      country: "",
      response: null,
      captchaError: false,
    };
  },
  created() {
    this.setSocialUserData();
    //  this.getCountrycode();
  },
  methods: {
    setSocialUserData() {
      var loc_SocialData = localStorage.getItem("socialData");
      if (loc_SocialData) {
        this.socialData = JSON.parse(loc_SocialData);
        this.socialUserData = this.socialData.user;
        this.username = this.socialUserData.name;
        this.name = this.socialUserData.name;
        this.email = this.socialUserData.email;
        this.unique_id = this.socialUserData.id;
        this.login_mode = this.socialData.provider;
      }
    },
    onVerify(response) {
      this.response = response;
      console.log(" captcha response >>>>>  ", response);
    },
    errorMethod(response) {
      console.log(" captcha Error >>>>>  ", response);
    },
    onSelect({ iso2, dialCode }) {
      this.countrycode = dialCode;
      this.country = iso2;
    },
    sendOtp() {
      this.errors = [];
      // this.loader = true;
      //     if(this.response == null){
      //          this.loader = false;
      // this.$swal("Error", "Please select the captcha box");
      //          this.captchaError = true;
      //      } else{
      let apiUrl = process.env.VUE_APP_URL + "customer/sendOtpWeb";
      let method_type = "post";
      fetch(apiUrl, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          name: this.name,
          username: this.username,
          gender: this.gender,
          email: this.email,
          unique_id: this.unique_id,
          login_mode: this.login_mode,
          mobile: this.mobile,
          password: this.password,
          confirm_password: this.confirm_password,
          country_code: this.countrycode,
          country: this.country,
          captchaError: false,
          gRecaptcha: this.response,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          // this.loader = false;
          if (data.status === true) {
            this.$bvModal.show("sms-modal");
          }
          if (data.status === false) {
            if (data.message == "The number is not a valid phone number.") {
              this.$bvModal.show("sms-modal");
            } else {
              this.$swal("Error", data.message, "error");
            }
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          console.log("Register Data", "Error : " + err.message, "error");
        });
      //}
    },
    checkOtp(otp) {
      this.errors = [];
      this.loader = true;
      let apiUrl = process.env.VUE_APP_URL + "customer/verifyotpPin";
      let method_type = "post";
      fetch(apiUrl, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          name: this.name,
          username: this.username,
          gender: this.gender,
          email: this.email,
          mobile: this.mobile,
          unique_id: this.unique_id,
          login_mode: this.login_mode,
          password: this.password,
          confirm_password: this.confirm_password,
          country_code: this.countrycode,
          country: this.country,
          otp: otp,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          // this.loader = false;
          if (data.status === true) {
            this.loader = true;
            this.register();
          }
          if (data.status === false) {
            this.$swal("Error", data.message, "error");
            this.otp = null;
          } else {
            this.errors = data.errors;
            this.otp = null;
          }
          this.loader = false;
        })
        .catch(function(err) {
          this.loader = false;
          console.log("Register Data", "Error : " + err.message, "error");
        });
    },
    register() {
      //Save or update User Details
      this.errors = [];
      this.loader = true;
      let apiUrl = process.env.VUE_APP_URL + "customer/customerRegisterWeb";
      let method_type = "post";
      fetch(apiUrl, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          name: this.name,
          username: this.username,
          gender: this.gender,
          email: this.email,
          mobile: this.mobile,
          password: this.password,
          unique_id: this.unique_id,
          login_mode: this.login_mode,
          confirm_password: this.confirm_password,
          country_code: this.countrycode,
          country: this.country,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          if (data.status === true) {
            var userData = {
              user: data.customer,
              token: data.token,
              status: true,
            };
            localStorage.setItem("userData", JSON.stringify(userData));
            window.location.href = "home";
            // this.resetForm();
          }
          if (data.status === false) {
            this.$swal("Error", data.message, "error");
          } else {
            this.loader = false;
            this.errors = data.errors;
          }
        })
        .catch(function(err) {
          console.log("Register Data", "Error : " + err.message, "error");
        });
    },
    resetForm() {
      this.name = null;
      this.username = null;
      this.gender = null;
      this.email = null;
      this.password = null;
      this.mobile = null;
      this.confirm_password = null;
      this.errors = [];
      this.response = null;
      this.captchaError = false;
      grecaptcha.reset();
    },
  },
};
</script>
